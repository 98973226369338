import { Card, Descriptions, message, Spin, Collapse } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import config from "../../config.json";
import { clearToken } from "../../scripts/token";
import { AddNewStore, ClientPayments, StoreList } from "./components";
import ActivateAnalytics from "./components/ActivateAnalytics";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AnalyticsAccess from "./components/AnalyticsAccess";
import { ActivateExpressAnalytics } from "./components/ActivateExpressAnalytics";
import useIsMobile from "../../hooks/useIsMobile";

const { Panel } = Collapse;

const ClientInfo = () => {
  const params = useParams();
  const clientId = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState();
  const isMobile = useIsMobile()
  useEffect(() => {
    fetchClient();
  }, [clientId]);

  const fetchClient = () => {
    axios
      .get(`${config.service}/api/client/${clientId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setClient(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const role = localStorage.getItem("role") || "";

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <PageHeader
            className="site-page-header"
            title="Информация о клиенте"
            onBack={() => {
              window.location.href = `/client`;
            }}
          />
          <div>
            <AddNewStore user={client} />
            <ActivateAnalytics user={client} />
            <ActivateExpressAnalytics user={client} />
          </div>
        </div>
        <Descriptions labelStyle={{color: '#202020'}}size="middle" column={1}>
          <Descriptions.Item label="Имя">
            <b>{client?.name}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Фамилия">
            <b>{client?.surname}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Номер телефона">
            <b>{client?.email}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Дата регистрации">
            <b>{new Date(client?.createdAt).toLocaleString()}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Реферальный код">
            <div className="d-flex align-items-center">
              <b>{client?.userReferral?.code}</b>
              <CopyToClipboard
                text={`https://app.salescout.me/registration?refCode=${client?.userReferral?.code}`}
                onCopy={() => {
                  message.success("Ссылка успешно скопирована");
                }}
              >
                <div className="border rounded px-1 cursor-pointer ms-2">
                  <CopyOutlined />
                </div>
              </CopyToClipboard>
            </div>
          </Descriptions.Item>
        </Descriptions>

        <Collapse>
          <Panel header="Доступы к аналитике">
            <AnalyticsAccess client={client} />
          </Panel>
        </Collapse>

        <StoreList clientId={clientId} />

        {role !== "DEVELOPER" && role !== "" && (
          <ClientPayments clientId={clientId} />
        )}
      </div>
    </Card>
  );
};

export default ClientInfo;
