import useIsMobile from "../../hooks/useIsMobile";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config.json";
import {CategoryTree} from "../../components/CategoryTree/CategoryTree";
import {MappingInformation} from "../../components/mapped-products/MappingInformation";
import {Loading} from "../../components/Loading";
import {Pagination} from "antd";
import {createSearchParams, useNavigate} from "react-router-dom";

const CATEGORY_URL = `${config.service}/api/mapping-dashboard/categories`;
const PRODUCT_URL = `${config.service}/api/mapping-dashboard`;

const MappingDashboard = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [productsState, setProductsState] = useState("none");
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [kaspiTotalCount, setKaspiTotalCount] = useState(0);

    const [limit, setLimit] = useState(20);
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState("all");

    // Получение категорий
    const getCategories = async () => {
        try {
            const response = await axios.get(
                CATEGORY_URL,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error(`Error while fetching categories: ${error}`);
        }
    };

    useEffect(() => {
        setIsLoading(true)
        getCategories().then((response) => {
            setCategories(response);
            setIsLoading(false)
        });
    }, []);

    // Получение продуктов
    const getMappedProducts = async (category, page) => {
        try {
            const response = await axios.post(
                PRODUCT_URL,
                {
                    category,
                    limit,
                    page
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                    },
                }
            );
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            console.error(`Error while fetching products: ${error}`);
        }
    };

    // Обработка выбора категории
    const handleCategorySelect = (category) => {
        console.log(`HANDLING ${category.name}`);
        setSelectedCategory(category);
        setIsLoading(true);
        setProducts([]);
        setProductsState("loading");
        setCurrentPage(1); // Сбрасываем текущую страницу на первую
    };

    // Следим за изменением категории и текущей страницы
    useEffect(() => {
        if (selectedCategory !== null) {
            setIsLoading(true)
            getMappedProducts(selectedCategory.name, currentPage).then((response) => {
                console.log(response.products)
                setProducts(response.products);
                setTotalCount(response.ozonTotalCount);
                setKaspiTotalCount(response.kaspiTotalCount);
                setIsLoading(false);
                setProductsState(response.kaspiTotalCount > 0 ? "nonEmpty" : "empty");
            });
        }
    }, [selectedCategory, currentPage]); // Срабатывает при изменении категории или текущей страницы

    // Пагинация
    const getPagination = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                    current={currentPage}
                    total={totalCount}
                    disabled={isLoading}
                    onChange={(value) => {
                        if (value !== currentPage) {
                            setCurrentPage(value);
                        }
                    }}
                    defaultCurrent={currentPage}
                    showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                    onShowSizeChange={async (current, size) => {
                        if (size !== limit) {
                            setLimit(size);
                            setCurrentPage(1); // Сбрасываем на первую страницу при изменении лимита
                        }
                    }}
                    pageSize={limit}
                    defaultPageSize={limit}
                />
            </div>
        );
    };

    return (
        <div className="mapping-dashboard">
            <h1 style={{ margin: "24px" }}>Mapping Dashboard</h1>
            <div className="dashboard-container">
                <div className="cateogry-container">
                    <h3 style={{ margin: "8px" }}>Выберите категорию:</h3>
                    <CategoryTree
                        categories={categories}
                        onSelect={handleCategorySelect}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                </div>
                <div className="mapping-info">
                    <div className="main-mapping-info">
                        {productsState === "empty" && (
                            <div>
                                <h2>Категория все еще не смаплена или спаршена некорректно</h2>
                            </div>
                        )}
                        {productsState === "none" && (
                            <div className="choose-category-text">
                                <h2>Выберите категорию</h2>
                            </div>
                        )}
                        <div>
                            <MappingInformation
                                products={products}
                                isLoading={isLoading}
                                productsState={productsState}
                                totalCount={totalCount}
                                kaspiTotalCount={kaspiTotalCount}
                                priceDifference={selectedCategory?.priceDifference}
                            />
                            {getPagination()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MappingDashboard;
