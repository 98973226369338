import { Descriptions, Modal } from "antd";
import { useState } from "react";
import { currencyFormat } from "../../../scripts/format";

export const ActivatedUserStats = ({ month }) => {
  const [visible, setVisible] = useState(false);

  const users = month?.users;
  if (!month || !users) {
    return null;
  }

    let keys = Object.keys(users);
    const role = localStorage.getItem("role") || "";
    console.log("ID "+localStorage.getItem("adminId"));
    const adminId = localStorage.getItem("adminId");
    const pointerId = keys
        .map(key => users[key]) // Create an array of user objects
        .filter(user => user?.user !== null) // Filter out null users
        .find(user => user.user._id === adminId)?.user._id;
    if(pointerId!==undefined){
        console.log("POINTER IS "+pointerId);
    }
  return (
    <div>
      {(role === "SUPER_ADMIN" || role === "MANAGER" ) && (
        <div
          style={{
            color: "blue",
            fontSize: 13,
            marginLeft: 20,
            cursor: "pointer",
          }}
          onClick={() => setVisible(true)}
        >
          Подробнее
        </div>
      )}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        title={month.monthName}
      >
        <div>
          {keys.map((key, index) => {

               const user = users[key];

            // Проверка на существование объекта user
            if (!user) {
              return null; // Или можно добавить сообщение об отсутствии данных
            }
            if(user){
                console.log("FORMAT ", user);
            }

            let userName = user.user?.login || "Пользователь не найден";
            if(pointerId !== undefined) {
                if(user?.user?._id !== localStorage.getItem("adminId")) {
                  // Хардкод чтобы Сымбат видела зп всех, кроме Бахи
                  if (localStorage.getItem("adminId") !== "62deec85d6fb16ca91de31ec" || user?.user?._id === "62deec87d6fb16ca91de31f6") {
                    return null
                  }
                }
            }
            return (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Descriptions
                  style={{
                    margin: "10px 0",
                  }}
                  title={userName + ` (${user.count})`}
                >
                  <Descriptions.Item label="Новые" span={3}>
                    <b>{currencyFormat(user.bonus.new)}</b>{" "}
                    <span style={{ marginLeft: 10 }}>
                      ({currencyFormat(user.price.new)})
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Старые" span={3}>
                    <b>{currencyFormat(user.bonus.old)}</b>
                    <span style={{ marginLeft: 10 }}>
                      ({currencyFormat(user.price.old)})
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Сумма" span={3}>
                    <b>{currencyFormat(user.bonus.total)}</b>
                    <span style={{ marginLeft: 10 }}>
                      ({currencyFormat(user.price.total)})
                    </span>
                  </Descriptions.Item>
                </Descriptions>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};
