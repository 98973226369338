import { useEffect, useState } from "react";
import { currencyFormat } from "../../../scripts/format";
import config from "../../../config.json";
import axios from "axios";
import { Table } from "antd";

const ExpectTodayPaid = () => {
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);

    axios
      .get(`${config.service}/api/payment/expect?day=today`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setData(data.data.data);
        setTotalPrice(data.data.totalPrice);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Магазин",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Сумма",
      dataIndex: "sum",
      key: "sum",
    },
  ];

  const dataSource = [];
  let i = 1;
  for (let payment of data) {
    dataSource.push({
      number: i++,
      storeName: <a href={`/store/${payment._id}`}>{payment.name} {(payment.type && payment.type === "REWIEWS_SENDING") ? (<><img style={{width: 16, height: 16}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1022px-WhatsApp.svg.png"/></>) : (<></>) } </a>,
      sum: currencyFormat(payment.expectedPaymentPrice),
    });
  }

  return (
    <div
      style={{
        maxWidth: 800,
        minWidth: 360,
        width: "50%",
      }}
    >
      <div
        style={{
          border: "1px solid lightgray",
          padding: 8,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: 15,
        }}
      >
        <h1 style={{ fontSize: 15 }}>Ожидаемые оплаты на сегодня</h1>
        <h1 style={{ fontSize: 15 }}>{currencyFormat(totalPrice)} KZT</h1>
      </div>
      <Table
        size="small"
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
};

export default ExpectTodayPaid;
