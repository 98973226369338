import React, { useState } from "react";
import {DownOutlined, RightOutlined} from "@ant-design/icons";
// Компонент для отображения категорий
export const CategoryTree = ({ categories, onSelect, selectedCategory, level = 0 }) => {
    return (
        <ul>
            {categories.map((category) => (
                <CategoryNode
                    key={category.code}
                    category={category}
                    onSelect={onSelect}
                    selectedCategory={selectedCategory}
                    level={level}
                />
            ))}
        </ul>
    );
};

// Компонент для узла (категории)
const CategoryNode = ({ category, onSelect, selectedCategory, level }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSelect = () => {
        // Выбираем только, если нет подкатегорий
        if (!category.subcategories || category.subcategories.length === 0) {
            onSelect(category);
        }
    };

    return (
        <li>
            <div
                onClick={handleToggle}
                style={{
                    cursor: "pointer",
                    paddingLeft: `${level * 20}px`, // Отступ увеличивается в зависимости от уровня
                }}
            >
                {category.subcategories && category.subcategories.length > 0 && (
                    <div className="expand-btn">
                        <span>
                            {isExpanded ? (<DownOutlined style={{width: "12px", height: "12px"}}/>) : (<RightOutlined style={{width: "12px", height: "12px"}}/>)}
                        </span>
                        {/*<span style={{ marginLeft: "8px" }}>*/}
                        {/*    {category.name}*/}
                        {/*</span>*/}
                        {
                            !category.priceDifference.price && <img style={{margin: "2px"}} width={"15px"} height={"15px"} src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"></img>
                        }
                        {
                            category.priceDifference.price && category.priceDifference?.price > 0 && <img style={{margin: "2px"}} width={"15px"} height={"15px"} src="https://companieslogo.com/img/orig/KKS.F-9d710a31.png?t=1720244492"></img>
                        }
                        {
                            category.priceDifference.price && category.priceDifference?.price < 0 && <img style={{margin: "2px"}} width={"15px"} height={"15px"} src="https://pngimg.com/uploads/ozon/ozon_PNG3.png"></img>
                        }

                        {/* </div> */}
                        <label>{category.name}</label>
                        <label style={{marginLeft: "4px"}}>  {Math.abs(Math.round(category.priceDifference?.percent))}%</label>
                        <label>
                            {/*{*/}
                            {/*    !category.priceDifference?.type &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"/>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    category.priceDifference?.type && category.priceDifference?.type === "NONE" &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"/>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    category.priceDifference?.type && (category.priceDifference?.type === "PASS" || category.priceDifference?.type === "PASS ONLY IMAGE") &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://www.svgrepo.com/show/340721/no-image.svg"/>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    category.priceDifference?.type && category.priceDifference?.type === "PASS NO IMAGE COMPARE" &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://cdn-icons-png.flaticon.com/512/1160/1160358.png"/>*/}
                            {/*}*/}
                        </label>
                    </div>
                )}
            </div>
            {/* Радио-кнопка отображается только для конечных категорий */}
            {!category.subcategories || category.subcategories.length === 0 ? (
                <div className="no-expand-btn" style={{ paddingLeft: `${level * 20 + 20}px` }}>
                    <div onClick={handleSelect} style={{display: "flex", alignItems: "inherit"}}>
                        <input
                            type="radio"
                            name="category"
                            checked={selectedCategory?.code === category.code}
                            onChange={handleSelect}
                            style={{marginRight: "5px"}}
                        />
                        {/* <div> */}
                        {
                            !category.priceDifference.price &&
                            <img style={{margin: "2px"}} width={"15px"} height={"15px"}
                                 src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"></img>
                        }
                        {
                            !!category.priceDifference.price && category.priceDifference?.price > 0 &&
                            <img style={{margin: "2px"}} width={"15px"} height={"15px"}
                                 src="https://companieslogo.com/img/orig/KKS.F-9d710a31.png?t=1720244492"></img>
                        }
                        {
                            !!category.priceDifference.price && category.priceDifference?.price < 0 &&
                            <img style={{margin: "2px"}} width={"15px"} height={"15px"}
                                 src="https://pngimg.com/uploads/ozon/ozon_PNG3.png"></img>
                        }

                        {/* </div> */}
                        <label>{category.name}</label>
                        <label style={{marginLeft: "4px"}}>  {Math.abs(Math.round(category.priceDifference?.percent))}%</label>
                        <label style={{marginLeft: "4px"}}>
                            {/*{*/}
                            {/*    !category.priceDifference?.type &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"/>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    category.priceDifference?.type && category.priceDifference?.type === "NONE" &&*/}
                            {/*    <img style={{margin: "2px"}} width={"15px"} height={"15px"}*/}
                            {/*         src="https://w7.pngwing.com/pngs/434/226/png-transparent-computer-icons-empty-string-youtube-empty-set-row-youtube-triangle-computer-programming-rim.png"/>*/}
                            {/*}*/}
                            {
                                category.priceDifference?.type && (category.priceDifference?.type === "PASS" || category.priceDifference?.type === "PASS ONLY IMAGE") &&
                                <img style={{margin: "2px"}} width={"15px"} height={"15px"}
                                     src="https://cdn-icons-png.flaticon.com/512/1160/1160358.png"/>
                            }
                            {
                                category.priceDifference?.type && category.priceDifference?.type === "PASS NO IMAGE COMPARE" &&
                                <img style={{margin: "2px"}} width={"15px"} height={"15px"}
                                     src="https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"/>
                            }
                        </label>
                    </div>

                </div>
            ) : null}
            {isExpanded && category.subcategories && category.subcategories.length > 0 && (
                <div className={`collapsable ${isExpanded ? "open" : ""}`}>
                    <CategoryTree
                        categories={category.subcategories}
                        onSelect={onSelect}
                        selectedCategory={selectedCategory}
                        level={level + 1} // Передаем уровень вложенности +1 для подкатегорий
                    />
                </div>
            )}
        </li>
    );
};
