import {
  Button,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Skeleton,
  Switch,
  Table,
  Spin,
  Select,
  Card,
  Checkbox,
} from "antd";
import { PageHeader } from '@ant-design/pro-components';
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import config from "../../config.json";
import { Line } from "@ant-design/charts";
import { clearToken } from "../../scripts/token";
import { Link } from "react-router-dom";
import { currencyFormat } from "../../scripts/format";
import {
  Privilege,
  StorePaymentInfo,
  ActivateStoreModal,
  ActivatePrivilegedStore,
  ResetStoreActivation,
  AdditionalCity,
} from "./components/";
import StoreActionHistory from "./components/StoreActionHistory";
import OrderApiToken from "./components/OrderApiToken";
import DailyAnalyticsModal from "./components/DailyAnalyticsModal";
import AddStoreAnalyticsGatheringButton from "./components/AddStoreAnalyticsGatheringButton";
import ActivateOrderStatusModal from "./components/ActivateOrderStatusModal";
import XmlUploadToKaspiHistory from "./components/XmlUploadToKaspiHistory";
import StoreStateHistory from "./components/StoreStateHistory";
import ActivateDeliveryDempingModal from "./components/ActivateDeliveryDempingModal";
import ActivateReviewsSendModal from "./components/ActivateReviewsSendModal";
import useIsMobile from "../../hooks/useIsMobile";

const { Panel } = Collapse;

const StoreInfo = () => {
  const params = useParams();
  const storeId = params.id;
  const isMobile = useIsMobile()
  const [store, setStore] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [isActivateStore, setIsActivateStore] = useState(false);

  const [feature, setFeature] = useState(null);

  useEffect(() => {
    fetchStore();
  }, [storeId]);

  useEffect(() => {
    fetchFeature();
  }, [storeId])

  const fetchStore = () => {
    axios
      .get(`${config.service}/api/store/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setStore(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchFeature = () => {
    axios
      .get(`${config.service}/api/store/${storeId}/66a9d64d68beee720bc1f3d0`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        console.log("Fetched Store Data:", data);
        setFeature(data.data)
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
  }
  if (isLoading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  const role = localStorage.getItem("role") || "";

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <div>
        <PageHeader
          className="site-page-header w-100 me-3"
          title="Информация о магазине"
          onBack={() => {
            window.location.href = `/store`;
          }}
        />

        <ActivateStoreModal
          isActivateStore={isActivateStore}
          setIsActivateStore={setIsActivateStore}
          store={store}
        />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <StoreDetails store={store} isLoading={isLoading} feature={feature} />
          <StorePaymentInfo store={store} />
        </div>

        {role !== "DEVELOPER" && role !== "" && (
          <div className="d-flex justify-content-between mb-3">
            <ActivatePrivilegedStore store={store} />
            <Button
              type="primary"
              onClick={() => {
                setIsActivateStore(true);
              }}
              style={{ width: 200 }}
              className="mb-2"
            >
              Активировать магазин
            </Button>
            <AdditionalCity store={store} />
            <ResetStoreActivation store={store} />
          </div>
        )}

        {role !== "DEVELOPER" && role !== "" && (
          <div className="mb-3">
            <div style={{display: "flex"}} >
              <DailyAnalyticsModal storeId={store._id} />
              <ActivateOrderStatusModal store={store} />
              <ActivateDeliveryDempingModal store={store} />
              <ActivateReviewsSendModal store={store} />
            </div>
            <div className="mt-3">
              <AddStoreAnalyticsGatheringButton storeId={store._id} />
            </div>
          </div>
        )}

        <Collapse defaultActiveKey={[]}>
          <Panel header="Товары" key="2">
            <ShowProductStatistics storeId={storeId} />
          </Panel>
          <>
            <Panel header="Заказы" key="1">
              <ShowStoreOrderStatistics
                storeId={storeId}
                isLoading={isLoading}
              />
            </Panel>
            {role !== "DEVELOPER" && role !== "" && (
              <Panel header="Платежи" key="3">
                <ShowStorePayments storeId={storeId} />
              </Panel>
            )}
          </>
          <Panel header="Настройки" key="4">
            <StoreSettings store={store} />
          </Panel>
          {store.privilege && (
            <Panel header="Привилегия" key="5">
              <Privilege store={store} />
            </Panel>
          )}
          <Panel header="История действий" key="6">
            <StoreActionHistory store={store} />
          </Panel>
          <Panel header="API токен для заказов" key="7">
            <OrderApiToken store={store} />
          </Panel>
          <Panel header="История подгрузки XML в Kaspi" key="8">
            <XmlUploadToKaspiHistory store={store} />
          </Panel>
        </Collapse>
      </div>
    </Card>
  );
};

const StoreDetails = ({ store, isLoading , feature}) => {
  return (
    <div
      style={{
        width: "40%",
        minWidth: 350,
      }}
    >
      <Descriptions labelStyle={{color: '#202020'}}column={1} >
        <Descriptions.Item label="Название" >
          <b>{store?.name}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Логин">
          <b>{store?.login}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Бот оплачен до">
          <b
            style={{
              color: new Date(store?.expireDate) > new Date() ? "green" : "red",
            }}
          >
            {new Date(store?.expireDate).toLocaleString()}
          </b>
        </Descriptions.Item>
        <Descriptions.Item label="Рассылка в WhatsApp оплачена до">
          <b
            style={{
              color:
                feature?.featureExpireDate && new Date(feature.featureExpireDate).getTime() !== 0
                  ? new Date(feature.featureExpireDate) > new Date()
                    ? "green" 
                    : "red" 
                  : "red", 
            }}
          >
            {feature?.featureExpireDate && new Date(feature.featureExpireDate).getTime() !== 0
              ? new Date(feature.featureExpireDate).toLocaleString()
              : "не оплачено"}
          </b>
        </Descriptions.Item>
        {store.orderStatusExpireDate && (
          <Descriptions.Item label="Статус заказов оплачен до">
            <b
              style={{
                color:
                  new Date(store?.orderStatusExpireDate) > new Date() ? "green" : "red",
              }}
            >
              {new Date(store?.orderStatusExpireDate).toLocaleString()}
            </b>
          </Descriptions.Item>
        )}
        {store.dempingDeliveryExpireDate && (
          <Descriptions.Item label="Демпинг по доставке оплачен до">
            <b
              style={{
                color:
                  new Date(store?.dempingDeliveryExpireDate) > new Date() ? "green" : "red",
              }}
            >
              {new Date(store?.dempingDeliveryExpireDate).toLocaleString()}
            </b>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label="Дата регистрации"
          style={{
            color: new Date(store?.createdAt) > new Date() ? "green" : "red",
          }}
        >
          <b>{new Date(store?.createdAt).toLocaleString()}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Главный город">
          <b> {store?.mainCity?.name || "Алматы"}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Допустимое кол-во доп. городов">
          <b> {store?.cityLimit || 0}</b>
        </Descriptions.Item>
        <Descriptions.Item
          label="Макс. кол-во товаров"
          style={{
            color: new Date(store?.createdAt) > new Date() ? "green" : "red",
          }}
        >
          <b>{currencyFormat(store?.maxDempingProducts)}</b>
        </Descriptions.Item>
        <Descriptions.Item
          label="Пользователь"
          style={{
            color: new Date(store?.createdAt) > new Date() ? "green" : "red",
          }}
        >
          <b>
            <Link to={`/client/${store?.userId}`}>
              {store?.user?.name + " " + store?.user?.surname}
            </Link>
          </b>
        </Descriptions.Item>
        <Descriptions.Item
          label="Номер телефона"
          style={{
            color: new Date(store?.createdAt) > new Date() ? "green" : "red",
          }}
        >
          <b>{store?.user?.email}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Ссылка на наш XML файл">
          <b>
            <a
              href={`https://xmlv2.salescout.me/${store?._id}?t=OFSYFHP$*Ffjndaipdsaf&from=kaspi`}
              target="_blank"
              rel="noreferrer"
            >
              Ссылка
            </a>
          </b>
        </Descriptions.Item>
        {store?.integrationUrl && (
          <Descriptions.Item label="Ссылка на внешний XML">
            <b>
              <a href={store.integrationUrl} target="_blank" rel="noreferrer">
                Ссылка
              </a>
            </b>
          </Descriptions.Item>
        )}
        {store.isBadCredentials && (
          <Descriptions.Item
            style={{
              color: "red",
            }}
          >
            <div style={{ color: "red " }}>Неверные уч. данные</div>
          </Descriptions.Item>
        )}
        <div>
          <b>
            <a href={store?.url} target="_blank">
              Открыть магазин
            </a>
          </b>
        </div>
      </Descriptions>
    </div>
  );
};

const ShowStoreOrderStatistics = ({ storeId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [storeId]);

  const fetchData = () => {
    axios
      .get(`${config.service}/api/order/${storeId}/statistics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setData(formatData(data.data.data));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formatData = (data) => {
    const result = [];

    for (let i = 0; i < 24; i++) {
      const key = i > 9 ? i + "" : "0" + i;

      result.push({
        value: data[key] || 0,
        key,
      });
    }

    return result;
  };

  const conf = {
    data,
    yField: "value",
    xField: "key",
    title: "title",
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <a href={`/store/${storeId}/order`}>Открыть все заказы</a>
      </div>
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
        }}
      >
        <Line loading={isLoading} theme={"light"} {...conf} />
      </div>
    </div>
  );
};

const ShowStorePayments = ({ storeId }) => {
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPayments();
  }, [storeId]);

  const fetchPayments = () => {
    axios
      .get(`${config.service}/api/payment/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setPayments(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: "Дата активации",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Сумма",
      dataIndex: "sum",
      key: "sum",
    },
    {
      title: "Cкорость х2",
      dataIndex: "doubleSpeed",
      key: "doubleSpeed",
    },
    {
      title: "Кол-во месяца",
      dataIndex: "numberOfMonth",
      key: "numberOfMonth",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
  ];

  const data = [];
  for (let payment of payments) {
    data.push({
      sum: currencyFormat(payment.price),
      doubleSpeed: payment.doubleSpeed ? "Есть" : "Нет",
      numberOfMonth: payment.numberOfMonth,
      date: new Date(payment.createdAt).toLocaleDateString(),
      type: payment.type === "SUBSCRIPTION" ? "Подписка" : `Комиссия, 0.7%`,
    });
  }

  return (
    <Table
      bordered
      size="small"
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

const ShowProductStatistics = ({ storeId }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [marketplace, setMarketplace] = useState();
  const [isLoadingActualize, setIsLoadingActualize] = useState();
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    fetchData();
    checkMarketplace();
  }, [storeId]);

  const fetchData = () => {
    axios
      .get(`${config.service}/api/product/${storeId}/statistics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((d) => {
        setData(d.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkMarketplace = () => {
    axios
    .get(`${config.service}/api/store/${storeId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
      },
    })
    .then((response) => {
      const store = response.data;
      const storeUrl = store?.url;
      if (storeUrl && storeUrl.includes("kaspi.kz")) {
        setMarketplace("kaspi");
      } else {
        console.log("Marketplace is not Kaspi");
      };
    })
    .catch((err) => {
      console.error("Error checking marketplace:", err);
    });
  }

  
  const actualizeTotalCountProducts = async () => {
    if (marketplace === 'kaspi'){
      try {
        setIsError(false);
        setIsLoadingActualize(true);
        await axios.post(`${config.service}/api/actualize-totalcount/actualizeProducts`, {storeId: storeId}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
          },
        })
        fetchData();
        setIsLoadingActualize(false);
      } catch (error) {
        console.error("Request timed out:", error);
        setIsLoadingActualize(false);
        setIsError(true);
      }
    } else {
      console.error("Маркетплейс должен быть Каспий");
      setIsError(true);
    }
  };

  return (
    <div>
      <a href={`/store/${storeId}/products`}>Открыть все товары</a>
      <div
        style={{
          marginTop: 15,
        }}
      >
       <button style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "8px 9px", marginBottom: "15px", width: "135px", borderRadius: "5px", color:"#fff", backgroundColor: isError ? "#f24943" : "#1677ff" }} 
        onMouseEnter={(e) => (e.target.style.backgroundColor = isError ? "#dd594d" : "#198cf0")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = isError ? "#f24943" : "#1677ff")}
        onClick = {actualizeTotalCountProducts}>
          {isLoadingActualize && (<div className="spinner" style={{ marginRight: "10px", width: "15px", height: "15px", border: "3px solid #ccc", borderTop: "3px solid #1677ff", borderRadius: "50%", animation: "spin 1s linear infinite" }}></div>)}
          {isLoadingActualize ? "Loading..." : "Actualize"} 
        </button>
        
        {isLoading && <Skeleton active={true} />}
        {!isLoading && (
          <Descriptions labelStyle={{color: '#202020'}}column={1}>
            <Descriptions.Item label="Все товары">
              {data.totalCount}
            </Descriptions.Item>
            <Descriptions.Item label="Активные">
              {data.activeCount}
            </Descriptions.Item>
            <Descriptions.Item label="На первом месте">
              {data.firstPositionCount}
            </Descriptions.Item>
            <Descriptions.Item label="Ниже мин. цены">
              {data.minusCount}
            </Descriptions.Item>
            <Descriptions.Item label="Автоснижение откл.">
              {data.dempingDisabledCount}
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
    </div>
  );
};

const StoreSettings = ({ store }) => {
  const [apiToken, setApiToken] = useState(store.apiToken);
  const [login, setLogin] = useState(store.login);
  const [password, setPassword] = useState(store.password);
  const [isStarted, setIsStarted] = useState(store.isStarted);
  const [changePriceMethod, setChangePriceMethod] = useState(
    store?.changePriceMethod || "REQUEST"
  );
  const [requestType, setRequestType] = useState(store?.requestType || "OLD");
  const [userId, setUserId] = useState(store?.userId || "");
  const [isAutoUploadXml, setIsAutoUploadXml] = useState(
    store?.isAutoUploadXml || false
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setIsLoading(true);

    const hide = message.loading("Сохраняется...", 0);

    axios
      .patch(
        `${config.service}/api/store/${store._id}`,
        {
          apiToken,
          login,
          password,
          isStarted,
          changePriceMethod,
          requestType,
          userId,
          isAutoUploadXml,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then(() => {
        message.success("Сохранено!", 3);
      })
      .catch((err) => {
        message.error("Ошибка!", 3);

        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setTimeout(hide, 1);
        setIsLoading(false);
      });
  };

  const role = localStorage.getItem("role") || "";

  return (
    <Form>
      {role !== "DEVELOPER" && role !== "" && (
        <>
          <Form.Item>
            <h1>Учетные данные магазина</h1>
            <Form.Item label="Логин">
              <Input
                value={login}
                onChange={(event) => {
                  setLogin(event.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Пароль">
              <Input
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Form.Item>
          </Form.Item>
          <Divider />
        </>
      )}
      <Form.Item>
        <h1>ID пользователя</h1>
        <Input
          value={userId}
          onChange={(event) => {
            setUserId(event.target.value);
          }}
        />
      </Form.Item>
      <Divider />
      {role !== "DEVELOPER" && role !== "" && (
        <>
          <Form.Item>
            <h1>API Токен</h1>
            <Input
              value={apiToken}
              onChange={(event) => {
                setApiToken(event.target.value);
              }}
            />
          </Form.Item>
          <Divider />
        </>
      )}
      <Form.Item>
        <div className="d-flex align-items-center mb-3">
          <h1 className="m-0 p-0 me-3">Метод ценообразования</h1>
          <div>
            <Link to={`/guide/store/price-change-methods`} target="_blank">
              Как это работает?
            </Link>
          </div>
        </div>
        <Select
          value={changePriceMethod}
          options={[
            { value: "REQUEST", label: "Стандартный" },
            { value: "XML", label: "XML" },
            { value: "XML_COMBINED_REQUEST", label: "Комбинированные запросы" }
          ]}
          onChange={(v) => setChangePriceMethod(v)}
        />
      </Form.Item>
      <Divider />
      <Form.Item>
        <h1>Запросы</h1>
        <Select
          value={requestType}
          onChange={(v) => setRequestType(v)}
          options={[
            {
              value: "NEW",
              label: "Новые",
            },
            {
              value: "OLD",
              label: "Старые",
            },
          ]}
        />
      </Form.Item>
      <Divider />
      <Form.Item label="Запустить" valuePropName="checked">
        <Switch
          checked={isStarted}
          onChange={(event) => {
            setIsStarted(event);
          }}
        />
      </Form.Item>
      <Collapse defaultActiveKey={[]} className="mt-3 mb-3">
        <Panel header="История" key="1">
          <StoreStateHistory store={store} />
        </Panel>
      </Collapse>
      <Button
        loading={isLoading}
        disabled={isLoading}
        type="primary"
        onClick={handleSave}
      >
        Сохранить
      </Button>
    </Form>
  );
};

export default StoreInfo;
