import {Table} from "antd";
import {Loading} from "../Loading";


export const MappingInformation = ({products, isLoading, productsState, totalCount, kaspiTotalCount, priceDifference}) => {
    console.log(`State: ${productsState}`);
    return (
        <>

            <div className="table-header">
                {productsState === "nonEmpty" && !isLoading ? (
                    <>
                        <h3>
                            {`Общее количество товаров в категории Kaspi: ${kaspiTotalCount}`}
                        </h3>
                        <h3>
                            {`Количество товаров найденных на OZON: ${totalCount}`}
                        </h3>
                        {
                            priceDifference?.price &&
                                <h3>
                                    {`На ${priceDifference.price > 0 ? "Kaspi" : "OZON"} дешевле в среднем на ${(Math.round(Math.abs(priceDifference.price)) || 0)} тг`}
                                </h3>
                        }
                        
                    </>
                ) : (<></>)}

                <Table
                    className="table-table"
                    scroll={{y: "calc(100vh * 0.7)" }}
                    pagination={false}
                    loading={isLoading}
                    bordered
                    size="middle"
                    columns={[
                        {
                            title: "Kaspi",
                            dataIndex: "kaspi"
                        },
                        {
                            title: "Ozon",
                            dataIndex: "ozon",
                        }
                    ]}
                    dataSource={products.map((product) => {
                        return {
                            key: product._id,
                            data: product,

                            kaspi: (
                                <div className="table-item-container">
                                    <img src={product.kaspiImg.small} width={48} height={48} />
                                    <div className="table-item-div">
                                        <a className="table-item-a" href={product?.kaspiUrl?.indexOf("kaspi.kz/") === -1 ? "https://kaspi.kz/shop" + product.kaspiUrl : product.kaspiUrl}>{product.kaspiName}</a>
                                        {product?.kaspiPrice === 0 && product?.availableOnKaspi === false && (
                                            <div className="not-available">
                                                <p>
                                                  Нет данных
                                                </p>
                                                <p>
                                                  Недоступно на Kaspi
                                                </p>
                                            </div>
                                        )}
                                        {product?.kaspiPrice !== 0 && product?.availableOnKaspi === false && (
                                            <div className="not-available">
                                                <p className="table-item-p">
                                                    {product?.kaspiPrice?.toLocaleString('ru-RU')} ₸
                                                </p>
                                                <p>
                                                    Недоступно на Kaspi
                                                </p>
                                            </div>
                                        )}
                                        {product?.availableOnKaspi && (
                                            <p className="table-item-p">
                                                {product?.kaspiPrice?.toLocaleString('ru-RU')} ₸
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ),
                            ozon: (
                                <div className="table-item-container">
                                    <img src={product.img} width={48} height={48}/>
                                    <div className="table-item-div">
                                        <a className="table-item-a"
                                           href={"https://ozon.kz" + product.productUrl}>{product.title}</a>
                                        <p className="table-item-p">
                                            {product?.price?.toLocaleString('ru-RU')} ₸
                                        </p>

                                    </div>
                                </div>
                            ),
                        }
                    })}
                />
            </div>
        </>
    )
}